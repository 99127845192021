import type { MetaFunction } from '@remix-run/node';
import { isRouteErrorResponse, useLoaderData, useRouteError } from '@remix-run/react';
import { Layout } from '~/pages/layout';
import { Page } from '~/pages/page';
import { homepageServiceMock } from '~/services/mock/homepage.service.mock';
import { homepageService } from '~/services/homepage.service';
import { metadataServiceMock } from '~/services/mock/metadata.service.mock';
import { metadataService } from '~/services/metadata.service';
import { PageData } from '~/models/page-data.model';
import { populateMetadata } from '~/utils/pupulate-metadata';
import { ErrorPage } from '~/pages/error';
import { NotFoundPage } from '~/pages/not-found';
import { localeService } from '~/services/locale.service';
import { getLocaleFromPath } from '~/utils/locale';
import { generateMetaTag } from '~/utils/generate-meta-tag';

export async function loader({ params }: any) {
  let pageData: PageData = new PageData();
  const lang = getLocaleFromPath(params['*']);

  if (process.env.MOCK === 'true') {
    pageData = await homepageServiceMock.getHomepage(lang);
    const metadata = await metadataServiceMock.getMetadata(lang);
    pageData.metadata = populateMetadata(pageData.metadata, metadata);
  } else {
    pageData = await homepageService.getHomepage(lang);
    const metadata = await metadataService.getMetadata(lang);
    pageData.metadata = populateMetadata(pageData.metadata, metadata);
  }

  if (pageData.metadata) {
    if (process.env.MOCK === 'true') {
      pageData.metadata.availableLocales = ['en', 'pt', 'es'];
    } else {
      pageData.metadata.availableLocales = await localeService.getLocales();
    }
  }

  pageData.websiteName = process.env.WEBSITE_NAME;

  return pageData;
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return generateMetaTag(data);
};

export default function Index() {
  const data = useLoaderData<PageData>();
  return <Layout>{Page(data)}</Layout>;
}

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFoundPage />;
  } else {
    return <ErrorPage />;
  }
}
